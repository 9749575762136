<template>
    <div class="onboarding">
        <v-tour name="onboarding" :steps="onboardingSteps" :callbacks="tourCallbacks"
                :options="{ highlight: true, labels: labels }" />
    </div>
</template>

<script>
    import ApiService from '@/services/api.service'
    import { mapState } from 'vuex'
    import { NotificationMixin } from '@/config/mixins'
    import { hasRole } from '@/modules/Auth/helpers'


    export default {
        mixins: [ NotificationMixin ],
        data() {
            return {
                onboardingSteps: [
                    {
                        target: '[data-v-step="0"]',
                        header: { title: this.$t('onboarding title ') },
                        content: this.$t('onboarding content forms')
                    },
                    {
                        target: '[data-v-step="1"]',
                        header: { title: this.$t('onboarding title archives') },
                        content: this.$t('onboarding content archives')
                    },
                    {
                        target: '[data-v-step="2"]',
                        header: { title: this.$t('onboarding title documents') },
                        content: this.$t('onboarding content documents')
                    },
                    {
                        target: '[data-v-step="3"]',
                        header: { title: this.$t('onboarding title settings') },
                        content: this.$t('onboarding content settings')
                    },
                    {
                        target: '[data-v-step="4"]',
                        header: { title: this.$t('onboarding title profile') },
                        content: this.$t('onboarding content profile')
                    },
                    {
                        target: '[data-v-step="5"]',
                        header: { title: this.$t('onboarding title help') },
                        content: this.$t('onboarding content help')
                    }
                ],
                tourCallbacks: {
                    onSkip: this.markOnboardingAsFinished,
                    onFinish: this.markOnboardingAsFinished
                },
                labels: {
                    buttonSkip: this.$t('Skip Onboarding'),
                    buttonPrevious: this.$t('Previous'),
                    buttonNext: this.$t('Next'),
                    buttonStop: this.$t('Finish')
                }
            }
        },
        computed: { ...mapState('auth', [ 'user' ]) },
        mounted() {
            // don't start onboarding on small screens
            if (!this.isScreenBigEnough()) {
                this.notifyError(this.$t('Unfortunately your screen is to small for the onboarding tutorial.'))
                return
            }

            let onboarding = this.$route.query.onboarding
            if ((!this.user.has_onboarding_completed || onboarding) && this.hasRole('administrator'))
                this.$tours['onboarding'].start()
        },
        methods: {
            hasRole,
            async markOnboardingAsFinished() {

                const data = { has_onboarding_completed: true }
                try {
                    await ApiService.patch(`/user/user/${this.user.id}/`, data)
                    await this.$store.dispatch('auth/fetchUser')
                } catch (e) {
                    let error = e.response ? e.response.data || e.response : e
                    if (typeof error === 'object')
                        error = Object.values(error).join(', ')
                    this.notifyError(error)
                }
            },
            isScreenBigEnough() {
                const minWidthForOnboarding = 1024

                const currentWidth = window.innerWidth
                    || document.documentElement.clientWidth
                    || document.body.clientWidth

                return currentWidth >= minWidthForOnboarding
            }
        }
    }
</script>

<style lang="scss">
    @import "~@/assets/vars";

    .onboarding {

        .v-step {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            min-width: 30rem;
            min-height: 15rem;
            background-color: $white;
            color: #4a4a4a;
            filter: none;
            -webkit-filter: none;
            text-align: left;
        }

        .v-step__header {
            background-color: $white;
            text-transform: uppercase;
            font-weight: bold;
            margin: 0 0 0.5rem 0;
            padding: 0;
            flex-grow: 0;
        }

        .v-step__content {
            border-top: solid $grey 2px;
            padding-top: 0.5rem;
            flex-grow: 1;
        }

        .v-step__buttons {
            flex-grow: 0;
        }

        .v-step__button {
            background: $white;
            border: 1px solid $light-grey;
            box-sizing: border-box;
            box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            color: $black;
            padding: 8px 16px;
            height: 42px;
            font-size: 16px;
            font-weight: bold;
        }

        .v-step__button:focus {
            color: inherit;
        }

        .v-step__arrow--dark {
            border-color: $white !important;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
        }

        .v-step__button-skip {
            background-color: #faf7ef;
            color: $main-grey;

            &:hover {
                color: $black;
                background-color: #faf7ef;
            }
        }
    }

    // shadow on behind the highlighted element on onboarding
    .v-tour__target--highlighted {
        box-shadow: 0 0 0 99999px rgba(0, 0, 0, .3);
    }

</style>
