<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-12">
                    <div class="message-like">
                        <BulbIcon />
                        {{ introText }}
                    </div>
                </div>
            </div>
            <router-view />
        </div>
        <Onboarding />
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import ManualPDF
        from '!file-loader?name=HygieneUndLebensmittelsicherheit_2019_de.pdf!@/assets/Ordner1_EDG_Grundlagen_2019_de_Inhalt_korr.pdf'
    import Onboarding from '../Onboarding/Onboarding'
    import BulbIcon from '@/components/icons/Bulb'

    export default {
        components: { Onboarding, BulbIcon },
        data() {
            return {
                introText: this.$t('Form intro text. Sed semper aenean lobortis urna mi, amet neque aptent conubia, curae rhoncus efficitur cursus.'),
                ManualPDF
            }
        },
        computed: { ...mapState('forms', [ 'formCategories' ]) },
        mounted() {
            this.fetch({ subtypes: true, reminders: true })
            this.$store.dispatch('settings/fetchSections')
        },
        methods: { ...mapActions('forms', [ 'fetch' ]) }
    }
</script>

<style>

</style>
